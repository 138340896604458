import React, { Component } from "react";



class Resume extends Component {

    render() {


        return (
            <div>
            </div>
        );
    }
}

export default Resume;