import React, { Component } from "react";
import "./index.css";

class Contact extends Component {
    render() {
        return (
            <div>
                <span>No</span>
            </div>
        );
    }
}

export default Contact;