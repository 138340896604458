import React, { Component } from "react";
import logo from './logo.svg';
import "./Home.css";
import "./App.css";
import "./index.css";
class Home extends Component {
    render() {
        return (
            <div>
                <h2>Hello All!</h2>
                <p>My name is Joe and I use this site for testing various small projects.  I'll occasionally update content here when I can.</p>
                <p>Here's some more text.</p>
                <img src={logo} className="App-logo" alt="logo" />

            </div>

        );
    }
}

export default Home;